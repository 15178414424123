import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getProfessionals,
  addProfessional,
  approveProfessional,
  showAddProfessionalModal,
  removeProfessional,
  editProfessional,
} from "../../store/professionals/actions";
import { connect } from "react-redux";
import ProfessionalList from "./List/ProfessionalList";
import { userRols } from "../../helpers/userRols";

class AllProfessionals extends Component {
  componentDidMount() {
    this.props.getProfessionals({ type: "professional" });
  }

  onRemove = (professional) => {
    this.props.removeProfessional(professional, userRols.vet);
  };

  onEdit = (professional) => {
    this.props.editProfessional(professional);
  };

  render() {
    const { professionalList, approveProfessional, history } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Professionals" breadcrumbItem="Professionals" />

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <ProfessionalList
                      list={professionalList}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      onApproveItem={approveProfessional}
                      history={history}
                      serviceType={userRols.vet}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Professional } = state;
  return Professional;
};

export default connect(mapStatetoProps, {
  getProfessionals,
  addProfessional,
  approveProfessional,
  showAddProfessionalModal,
  removeProfessional,
  editProfessional,
})(AllProfessionals);
